#MealPrepLandingSection { background-color: #e6e6e6 !important; padding:0px 0 30px 0;}
.mpm-lp-container { max-width: 920px; border-radius: 0px; background-color: #f2f2f3; box-shadow: 0 0 5px 0 rgba(0,0,0,0.25);  border: 0px none; margin-left:-15px; margin-right:-15px ; padding: 15px 0 0 0; overflow:hidden}
.mpm-header-logo {padding:15px; text-align:center; display:none;}
.content-inner-wrap { max-width: 676px; margin: 0px auto; }
.dpq-white { background-color: #ffffff; padding: 15px; font-family: 'Poppins'; }
.ft-landing{background-color:#e6e6e6 !important; color:#d7104c !important; margin:0; padding:10px 15px}
.bsh-wrap{box-shadow: 0 0 5px 0 rgba(0,0,0,0.25); }
.qTopLabel{text-align:center; color:#ffffff; background-color:#d7104c; padding:15px; margin:0; font-size:1rem; font-weight:bold}
.firstTitle h1{font-size: 1.2rem !important;}
.quizResult{ max-width:100%;}
@media(min-width:576px){
    .qTopLabel{font-size:1.2rem;}
    .mpm-lp-container{border-radius: 20px; margin-left:auto ; margin-right:auto ; border: solid 5px #ffffff; padding: 30px 0;}
    .firstTitle h1{font-size: 1.8rem !important;}
    .mpm-header-logo {display:block;}
    .quizResult{border:0px none; border-radius:0; max-width:85%; margin:15px auto;}
}