
#BcmDPHeader { background: #ffffff; position: relative; z-index: 5; }

.dpq-wrapper { max-width: 640px; margin: 0px auto; }
.dpq-progress{padding-top:15px}
.dpq-progress-nav { display: flex;}
.dpq-progress-nav a{color:#333;}
.dpq-progress-nav a:hover{color:#d7493b; text-decoration:none;}
.dpq-progress .progress-bar{background-color:#d7104c !important}
.dpq-progress .progress{height:8px; margin:8px 0 0 0}
.progress-qcount { margin-left: auto; margin-right:8px; text-align:right }
.dpq-question{margin:0 0 30px 0; display:none;}
.dpq-question h3{margin-bottom:15px; font-size:1.5rem}

.firstTitle h1{font-size:1.5rem; font-weight:700; margin:0; color:#333}
.firstTitle h3{font-size:1.8rem; font-weight:700; font-family:"Dancing Script"; color:#d7104c; line-height:100%}
.graphInfo{font-size:1.2rem; text-align:center; line-height:150%}
.genderSelect label{display:inline-block; width:42%; padding:10px 15px; margin:8px auto; background-color:#f2f2f3; border-radius:6px;  position:relative; cursor:pointer; border:2px solid #f2f2f3; color:#333; font-size:1rem; overflow:hidden; font-weight:600}
.genderSelect label:hover{background-color:#ffe2df; border-color:#ffe2df; color:#000}
.genderSelect label input[type='radio']{position:absolute; left:50px; bottom:0px; opacity:0}
.genderSelect label.selected{background-color:#ffe2df; border-color:#d7104c; color:#d7104c}

.multipleChoice label{display:block; padding:10px 15px; margin:8px 0px; background-color:#f2f2f3; border-radius:6px;  position:relative; cursor:pointer; border:2px solid #f2f2f3; color:#333; font-size:1rem; overflow:hidden}
.multipleChoice label:hover{background-color:#ffe2df; border-color:#ffe2df; color:#000}
.multipleChoice label input[type='radio']{position:absolute; left:50px; bottom:0px; opacity:0}
.multipleChoice label input[type='checkbox']{position:relative; top:-1px; margin-right:10px}
.multipleChoice label.selected{background-color:#ffe2df; border-color:#d7104c; color:#d7104c}

.dpq-profileForm{padding:30px; margin:15px auto 30px auto; background-color:#f2f2f3; border-radius:6px; max-width:500px }
.dpq-profileForm .input-group-text{color:#000; background-color:#ffe2df; width:60px; text-align:center; display:block;}
 
 
.dpq-profileForm label{margin-bottom:3px; font-size:0.9rem; font-weight:600}

.dpq-bodyshapes{display:flex; position:relative; overflow:hidden; justify-content:center; flex-wrap:wrap}
.dpq-bodyshapes label{text-align:center; width:45%; padding:10px 15px; margin:8px 5px; background-color:#f2f2f3; border-radius:6px;  position:relative; cursor:pointer; border:2px solid #f2f2f3; color:#333; font-size:1rem; overflow:hidden}
.dpq-bodyshapes label span{display:block}
.dpq-bodyshapes label input[type='radio']{position:absolute; left:50px; bottom:0px; opacity:0}
.dpq-bodyshapes label:hover{background-color:#ffe2df; border-color:#ffe2df; color:#000}
.dpq-bodyshapes label.selected{background-color:#ffe2df; border-color:#d7493b; color:#000}
.dpq-bodyshapes.male .img-female{display:none;}
.dpq-bodyshapes.female .img-male{display:none;}

.quickquiz label{display:inline-block; text-align:center; width:40%; padding:10px 15px; margin:8px 15px; background-color:#f2f2f3; border-radius:6px;  position:relative; cursor:pointer; border:2px solid #f2f2f3; color:#333; font-size:1.2rem; overflow:hidden}
.quickquiz label:hover{background-color:#ffe2df; border-color:#ffe2df; color:#000}
.quickquiz label input[type='radio']{position:absolute; left:50px; bottom:0px; opacity:0}
.quickquiz label.selected{background-color:#ffe2df; border-color:#d7104c; color:#d7104c}

.quizResult{padding:15px; background-color:#d3ffec; border-radius:6px; margin:8px 0; display:none; border:2px solid #25bf2d}
.quizResult div{font-size:0.9rem; color:#000; line-height:130%}
.cta-salmon{cursor:pointer}

.input-after{position:relative}
.input-after input{position:relative; -webkit-appearance:textfield; font-weight:600; font-size:1.3rem; padding:15px; color:white !important;}
.input-after-content {position:absolute;  font-weight:600; font-size:1.3rem; left:16px; top:20px; color:#999;}
.input-after-content .input-after-text{font-weight:400; font-size:1rem;}


.loader-wrapper{position:relative; text-align:center; }
.loading-image{max-width:150px; margin:30px auto;}
.loading-status h5{color:#000; display:none;}

.infos-personelles{max-width: 640px; margin: 0px auto;}
.smallLabel{font-size:0.8rem; color:#d7493b}
.form-check-label.accept{font-size:0.9rem; font-weight:400; line-height:140%; color:#333333}
 
.bcm-results-intro{display:block; align-items:center; text-align:left}
.bcm-results-intro-image{width:200px; min-width:200px; margin:15px auto; }
.bcm-results-intro-text{margin:15px; text-align:left}

.results-well{position:relative; margin:10px auto; border:2px solid #cecece; padding:20px 15px; border-radius:6px; background-color:#fff;}
.misc-data{display:flex; align-items:center}
.misc-data .rw-icon{font-size:36px; margin-right:15px; width:60px; min-width:60px; height:60px; text-align:center; background-color:#f2f2f3; border-radius:50%; line-height:56px}
 
.misc-data p{margin:0; color:#999999}
.misc-data h5{margin:0; color:#000; font-weight:600}
 
.mb-data{display:flex; align-items:flex-start; margin:10px auto; padding:10px 15px}
.mb-data .rw-illustration{ margin-right:15px; width:60px; min-width:60px; height:60px; text-align:center; background-color:#f2f2f3; border-radius:50%; display:flex; align-items:center}
.mb-data p{margin:0; color:#000000}
.mb-data .rw-illustration img{width:45px; display:block;}

.smweightgraph{display:flex; align-items:stretch; position:relative; max-width:610px; margin:15px auto}
.graphTitle{display:block; align-items:flex-end; margin-bottom:15px}
.graphValues{position:absolute; height:100%; width:30px; top:0; left:0; color:#1d1d1d}
.graphValues > div{height:24px;}
.graphValues .graph-startW{position:absolute; top:7%}
.graphValues .graph-targetW{position:absolute; bottom:20%}
.graphValues .graph-mid{position:absolute; top:43%; margin-top:-12px}
.lineGraphCanvas{position:relative; width:auto; transition:all 0.3s linear}
.lineGraphCanvas img{display:block; position:relative; z-index:1}
.lineGraphCanvas .graphLabels{position:absolute; bottom:0; width:16%; text-align:center; font-size:0.5rem; z-index:10; color:#999; font-weight:300 }

.graph-curve{max-width:884px;  position:relative; margin-top:30px; margin-left:-15px; margin-right:-15px}
.graph-curve > img{position:relative; z-index:1;}
.graph-curve .labels{position:absolute; width:110px; text-align:center; font:normal 14px "Poppins"; color:#000000; z-index:5; line-height:120%}
 
.labels.start-content{top:-12%; left:2%;}
.labels.end-content{top:12%; left:68%;}

.bmi-scale{max-width:480px; margin:15px auto 30px auto; position:relative; padding-top:50px;}
.bmi-scale img{position:relative}
.bmi-bubble { position: absolute; background: #04b7b7; border-radius: .4em; color:#fff; text-align:center;  top:0; left:55%; padding:8px 10px; font-weight:bold; margin-left:-27px }
.bmi-bubble:after { content: ''; position: absolute; bottom: 0; left: 50%; width: 0; height: 0; border: 10px solid transparent; border-top-color: #04b7b7; border-bottom: 0; margin-left: -10px; margin-bottom: -10px; }
.planholder{padding:15px; border-radius:15px; background-color:#ffe2df;max-width:600px; margin:15px auto;}
.planType{background-color:#fff;  border-radius:15px; margin:15px auto; position:relative; overflow:hidden}
.planType label{display:block; padding:30px 15px; display:flex; margin:0px; border:3px solid #fff; border-radius:15px;  overflow:hidden; transition:all 0.2s linear; cursor:pointer}
.planType label.selected{ border:3px solid #48868d;}
.planType.reco label{padding:60px 15px 30px 15px; }
.planType label .ppl { font-size: 1.4rem; font-weight:600; flex:1; margin-left:15px }
.planType label .ppl em{font-style:normal; font-size:1rem; color:#666; font-weight:400}
.planType label .fend { font-size: 1.4rem; font-weight:600;  margin-left:auto; }
.planType label .fend em{font-style:normal; font-size:1rem; color:#999;  font-weight:400}
.planType .reco-sm{background-color:#48868d; position:absolute; top:0; left:0; font-size:0.9rem; width:100%; padding:5px; text-align:center; color:#fff; font-weight:bold}
.features-img{max-width:350px; margin:0px auto;}
.bcm-tm-img { text-align: center; margin: 15px auto; max-width: 120px; min-width: 120px; }
.bcm-testimonial-carousel h5 { font-family: 'Dancing Script', cursive; color: #ffffff; font-size: 1.5rem; font-weight: 700; margin-bottom: 10px; }
.und{text-decoration:underline;}
.disclaimer{font-size:0.75rem; color:#eeeeee; line-height:130%}
.bottomBlack{background-color:#333333}
.floatingButton{position:fixed; z-index:100; width:100%; left:0; bottom:0;  background: rgb(255,255,255); background: linear-gradient(0deg, rgba(255,255,255,1) 0%, rgba(255,255,255,0) 100%); }

.bcmdp-errorMsg{padding:8px; font-size:0.8rem; color:#e30b0b; background-color:#ffd8d8; border-radius:4px; line-height:130%; display:none;}

@media(min-width:576px){
.bcm-tm-img { text-align: center; margin: 15px; max-width: 200px; min-width: 200px; }
.bcm-testimonial-carousel h5 {font-size: 2rem; }
.firstTitle h1{font-size:2rem;}
.firstTitle h3{font-size:2.5rem; }
.dpq-question h3{ font-size:1.8rem}

.dpq-bodyshapes label{width:23%; }
.genderSelect label{font-size:1.2rem; font-weight:600; }
.planholder{padding:50px 60px;}
.bcm-results-intro{display:flex;}
}