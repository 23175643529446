
#BcmDPHeader { background: #ffffff; position: relative; z-index: 5; }
.mainBlockBcmPayment { background: #ecafb4 url(https://mealprep.jeanmichelcohen.fr/images/bcm-results-hero-bg.jpg) no-repeat center center; background-size: auto; background-size: cover; padding: 0px; }
.mainBlockBcmPayment h1{font-size:1.8rem; font-weight:700; color:#333333; margin-bottom:20px; margin-top:20px; text-align:center}
.mainBlockBcmPayment h3{font-size:1.2rem; color:#fff; margin-bottom:30px; text-align:center}
.short-int{padding:20px; background-color:rgba(255,255,255,0.6); border-radius:15px; border:3px solid #d7104c; margin-bottom:50px}
.short-int p{margin:0; color:#d7104c; font-size:1.1rem;}
.specialBook{display:flex; align-items:center; margin-bottom:15px;  margin-left:auto; margin-right:auto; padding:15px; background-color:#fff; }
.lmc { min-width: 100px; max-width: 120px; margin-right:15px; display:block}

.fltBox{background-color:#e6e6e6; position:relative; padding:0px;   border-bottom:8px dashed #25bf2d; margin-bottom:30px; border-top-left-radius: 15px; border-top-right-radius: 15px}
.fltBox h3{margin:0; padding:15px; color:#fff !important; background-color:#25bf2d; font-size:1.5rem;  border-top-left-radius: 15px; border-top-right-radius: 15px}
.paychoice{padding:8px 15px; background-color:#fff; margin-bottom:1px}
#paymentSelect{padding:15px}
.informationForm{padding:15px}
.bcm-offers{padding:15px}

.rightcolHolder{box-shadow:0 0 15px rgba(0,0,0,.15); border-radius:15px; background-color:#ffffff;}
.payment-sidebox{padding:15px; border-bottom:1px solid #cecece;}
.payment-sidebox h5{text-align:center; color:#d7104c !important; font-weight:600}
.bda {background: #ffffff url(https://savoir-maigrir.aujourdhui.com/img/intercom-100.png) no-repeat right bottom !important;}
.mobtab{display:flex; align-items:center; margin-bottom:15px}
.control-label{font-weight:bold; color:#000; margin-bottom:0}

.arrowHere{position:absolute; left:50%; top:-100px}
.offerBounds{margin:15px; border:3px solid #fff; border-radius:15px; background-color:#ffffff; transition:all 0.3s linear; overflow:hidden}
.offerBounds h4{font-weight:600; color:#000; padding:15px; font-size:1.8rem; margin:0; font-family:"Dancing Script"}
.offerBounds h2{margin:0; padding:15px; background-color:#f2f2f3; font-size:1.5rem;text-align:center}
.offerBounds h2 span{font-weight:bold; color:#d7104c; font-size:2rem; }
.offerBounds ul{margin:0 0 0 15px; padding:15px; font-size:0.9rem;}
.offerBounds.selected{border:3px solid #d7104c;}
 .offerBounds.recommended h4 { color: #ffffff; background-color: #d7104c;}
 

.toggleRadio{cursor:pointer; display:block; position:relative; padding:15px; text-align:center; border:2px solid #d7104c; margin:30px;  background-color:#fff; color:#d7104c !important; font-weight:700; font-size:1.3rem; transition:all 0.3s linear}
.toggleRadio input[type="radio"]{position:absolute; left:-50px; bottom:-50px; opacity:0}
.toggleRadio:hover{background-color:#f2f2f3}
 
.offerBounds.selected .toggleRadio{background-color: #d7104c; color:#ffffff !important}
.offerBounds.selected h2 span{font-weight:bold; color:#d7104c; font-size:2rem; }

.offerBounds{position: relative; overflow: normal !important; z-index: 20;}
div.stickerOff{position:absolute; width:60px; height: 60px;   border-radius: 50%; background-color:red; color: #fff; top:-5px; right: 5px; z-index: 50; font-size: 14px; text-align: center; line-height: 58px; border:3px solid #fff; box-shadow: -5px 5px 15px rgba(0,0,0,0.2); transform: rotate(7deg);}
div.stickerOff strong{font-size: 20px;}

.disclaimer {
  font-size: 0.75rem;
  color: #333333;
  line-height: 130%;
}

@media(min-width:576px){

 }

@media(min-width:768px){
 .mainBlockBcmPayment h1{font-size:3em; text-align:left}
 .mainBlockBcmPayment h3{font-size:2rem; text-align:left}
}